@media (min-width: 768px) {
  nav {
    position: relative;
    border-radius: 0px 0px var(--border-radius-desktop) 0px;
    width: 85%;
    max-width: var(--container-width);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    padding-right: 2.8em;
  }

  .fixed-wrapper {
    width: 100%;
    position: absolute;
    z-index: 20000;
    top: 0;
    width: 90%;
    right: 0;
    left: 0; /* Move this out to the left of the site body, leaving a 20px gutter */
  }

  /* The element you want to fix the position of */
  .fixed-menu {
    width: 100%;
    position: fixed;
    /* Do not set top / left! */
  }
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .nav-desktop {
    display: flex;
    align-items: end;
    margin-right: 3em;
    ul {
      display: block;
      li {
        font-size: 15px;
      }
    }
  }
  .extracorner::after {
    width: var(--border-radius-desktop);
    height: calc(var(--border-radius-desktop) * 2);
    border-top-left-radius: var(--border-radius-desktop);
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0 white;
  }

  .hamburger {
    display: none;
  }

  .mainlogo {
    width: 196px;
    padding: 1em;
    padding-bottom: 0.75em;
  }

  .button-omhoog-link {
    display: none;
  }
  main {
    margin-top: 100px;
  }
  .landing {
    flex-direction: row-reverse;

    padding-bottom: 0;
    box-shadow: 0 -300px 0 0 var(--clr-green);
    &--werken-bij {
      box-shadow: 0 -300px 0 0 var(--clr-lightblue);
    }
  }
  .landing--yellow {
    box-shadow: 0 -300px 0 0 var(--clr-yellow);
  }
  .landing--red {
    box-shadow: 0 -300px 0 0 var(--clr-red);
  }
  .landing--lightblue {
    box-shadow: 0 -300px 0 0 var(--clr-lightblue);
  }

  .img-overlay-wrap {
    flex: 1 1 0px;
    position: relative;
  }
  .innercontainer {
    flex: 1 1 0px;
    max-width: 856px;
    &--werken-bij {
      max-width: 768px;
    }
  }

  .img-overlay-wrap img,
  .actie-image img {
    /* <= optional, for responsiveness */
    height: 100%;
  }

  .img-overlay-wrap .svg1 {
    display: none;
  }

  .actie-image .svg1 {
    display: none;
  }

  .img-overlay-wrap .svg1-desktop {
    display: block;
    position: absolute;
    top: 0px;
    right: -1px;
    z-index: 3;
    height: 100%;
    width: 120px;
  }

  .img-overlay-wrap svg {
    right: 0;
  }

  .birthday {
    position: absolute;
    z-index: 2;
    display: block;
    right: 40px;
    top: 50%;
    height: 145px;
    padding-right: 30px;

    border-top-left-radius: var(--border-radius-desktop);
  }
  .birthday-25 {
    font-size: 100px;
    margin-bottom: 0;
    line-height: 1;
  }
  .birthday-jaar {
    margin-top: 0;
    font-size: 43px;
    line-height: 1;
  }

  h1 {
    font-weight: bold;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0.23px;
    margin-top: 92px;
    margin-bottom: 16px;
    width: 40vw;
    max-width: 598px;
  }

  .landing-content p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    padding-right: 6em;
    width: 40vw;
    max-width: 530px;
  }

  .landing-content {
    padding-bottom: 60px;
  }

  h2 {
    font-size: 36px;
    font-weight: 900;
  }

  .vision {
    padding-top: 3em;
  }
  .vision p {
    font-size: 17px;
    max-width: 700px;
    margin: 0 auto;
  }

  .p-breed p {
    max-width: 800px;
  }
  .wijzijn {
    display: block;
    margin-bottom: 60px;
  }
  .wijzijn img {
    max-width: 100%;
  }
  .wijzijn-flex,
  .wwd-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2.5em;
  }
  .wijzijn-flex-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4em;
    margin-top: 1em;
    .waarde-4 {
      margin-left: auto;
    }
    .waardes {
      max-width: calc(66% - 2px);
    }
  }
  .watwijdoen {
    border-bottom-right-radius: var(--border-radius-desktop);
  }
  .watwijdoen::after {
    z-index: 1000;
    width: var(--border-radius-desktop);
    height: calc(var(--border-radius-desktop) * 2);
    border-top-left-radius: var(--border-radius-desktop);
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0
      var(--clr-darkblue);
  }

  .watwijdoen-lightblue::after {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0
      var(--clr-lightblue);
  }
  .wwd-grid {
    grid-template-rows: 3;
  }

  h3 {
    font-size: 18px;
  }
  .waardes img {
    width: 56%;
  }
  .waardes p {
    font-size: 15px;
  }

  .watwijdoen p {
    max-width: 672px;
    margin: 0 auto;
    margin-bottom: 2em;
    font-size: 17px;
  }
  .wwd-grid {
    padding-top: 5em;
  }
  .wwdfirstitem {
    padding-top: 0;
  }

  main ul {
    padding-left: 16px;
  }

  .watwijdoenopsomming .btn-full {
    margin-bottom: 2em;
  }

  .team-fotos {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0em 4em;
  }

  .photo {
    img {
      border-top-right-radius: var(--border-radius-desktop);
      border-bottom-left-radius: var(--border-radius-desktop);
      &:hover {
        border-top-left-radius: var(--border-radius-desktop);
        border-bottom-right-radius: var(--border-radius-desktop);
      }
    }
  }

  .photo-wrapper::before {
    border-top-right-radius: var(--border-radius-desktop);
    border-bottom-left-radius: var(--border-radius-desktop);
  }

  .wiewijzijn p {
    font-size: 15px;
  }
  .member-name {
    margin-bottom: 0;
  }

  .member-function {
    margin-top: 0;
    margin-block-start: 0;
    display: block;
  }

  .actievraag {
    display: flex;
    justify-content: end;

    // height: min-content;
  }

  .actievraag-content {
    flex: 0.6;
    padding-top: 0em;
    // padding-left: 10em;
    padding-bottom: 0;
    padding-right: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
  }

  .actievraag-content h2,
  .ontdek {
    font-size: 28px;
    line-height: 39px;
    max-width: 320px;
  }

  .actie-image {
    flex: 1;
    // height: 500px;
    object-fit: cover;
    // height: min-content;
    img {
      max-height: 404px;
      width: 100%;
    }
  }

  .svg2 {
    display: none;
  }

  .svg2-desktop {
    top: 0;
    left: 0;
    height: 100%;

    position: absolute;
    display: block;
  }

  .partners h2 {
    margin-top: 2em;
    margin-bottom: 1.1em;
  }
  .contact {
    padding-top: 4em;
    &-flex {
      display: flex;
      gap: 6em;
    }

    &-left {
      width: 60%;
    }

    &-info {
      width: 40%;

      justify-content: space-between;

      img {
        // align-self: end;
        padding-bottom: 5em;
        width: 400px;
        margin-bottom: 0;
      }
    }
    h2 {
      margin-top: 0;
    }
    img {
      // align-self: end;
    }
  }
  .page-contact h2 {
    margin-top: 1em;
  }
  footer {
    display: none;
  }
  .footer-desktop {
    width: 100%;
    display: grid;
    gap: 0 2em;
    color: white;
    margin-bottom: 5em;
    grid-template-areas:
      "white-space green-space . connect"
      "white-space green-space volgons connect"
      "white-space green-space disclaimer connect"
      "white-space green-space ontwerp connect"
      ". . . .";
    grid-template-columns: minmax(30px, 60px) minmax(30px, 45px) 1fr 1fr;
    grid-template-rows: 2em auto auto auto 2em;

    // .footer-menu-flex {
    //   grid-area: menu;
    //   display: flex;
    //   gap: 1em;
    //   padding-top: 3em;
    // }
    .footer-menu-items a {
      display: block;
      margin-bottom: 0.5em;
    }

    .footer-link-small {
      font-weight: 300;
    }

    .green-space {
      background-color: var(--clr-green);
      grid-area: 1 / 2 / -1 / -1;
      z-index: -1;
      border-bottom-left-radius: var(--border-radius-desktop);
    }

    .connect {
      grid-area: connect;
      background: var(--clr-darkblue);
      padding: 2em 2em 1em;
      border-bottom-left-radius: var(--border-radius-desktop);
      // min-width: 300px;
      h2 {
        margin-top: 0;
        color: white;
        font-size: 17px;
        max-width: 25ch;
        line-height: 1.6;
        margin-bottom: 1em;
      }
    }
    .ontwerp {
      grid-area: ontwerp;
    }
    .disclaimer {
      grid-area: disclaimer;
      margin-bottom: 0;
    }
    .volgons {
      grid-area: volgons;
      margin-bottom: 2em;
      img {
        width: 40px;
        margin-left: 1em;
      }
      display: flex;
      align-items: center;
    }

    a,
    p {
      color: white;
    }
  }
}
@media (min-width: 436px) {
  .informeer {
    padding-top: 115px;
  }
}

@media (min-width: 488px) {
  .informeer {
    padding-top: 100px;
  }
}

@media (min-width: 587px) {
  .informeer {
    padding-top: 83px;
  }
}

@media (min-width: 587px) {
  .informeer {
    padding-top: 83px;
  }
}

@media (min-width: 900px) {
  .innercontainer {
    max-width: 956px;
  }
  .innercontainer--werken-bij {
    max-width: 756px;
  }
  .innercontainer--ctp {
    max-width: 650px;
  }
}

@media (min-width: 1100px) {
  .footer-desktop .footer-menu-flex {
    gap: 2em;
  }
}

@media (min-width: 1400px) {
  .innercontainer {
    max-width: 1156px;
  }
  .innercontainer--werken-bij {
    max-width: 880px;
  }
  .innercontainer--ctp {
    max-width: 756px;
  }
  .landing-flex {
    display: flex;
    align-items: center;
  }
  .contact {
    img {
      max-height: 350px;
      width: auto;
      margin-left: 0;
    }
  }
}
@media (min-width: 1700px) {
  .fixed-menu {
    width: 90%;
  }
}

@media (min-width: 1870px) {
  .fixed-menu {
    width: 80%;
  }
}

@media (min-width: 2000px) {
  .fixed-menu {
    width: 70%;
  }
}
