html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
}

main {
  // margin-top: -92px;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
}

.innercontainer,
.innercontainer--werken-bij {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 656px;
  margin: 0 auto;
}
.innercontainer--ctp {
  max-width: 560px;
}
.landing {
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--clr-green);
  color: white;
  padding-bottom: var(--spacer-small);
}

.mainlogolink {
  z-index: 10000;
}

.img-overlay-wrap,
.actie-image {
  position: relative;

  display: inline-block;
}

.img-overlay-wrap img,
.actie-image img {
  height: 100%;
  object-fit: cover;
  display: block;
  max-width: 100%;
  width: 100%;
  height: 60vh;
}
.actie-image img {
  height: 100%;
}

.img-overlay-wrap .svg1-desktop {
  display: none;
}

.actie-image .svg1-desktop {
  display: none;
}
.birthday {
  display: none;
}

.img-overlay-wrap .svg1 {
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 3;
  height: 90px;
  width: 100%;
}

.actie-image .svg2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  // height: 30px;
  width: 100%;
}

.svg2-desktop {
  display: none;
}

.landing p {
  color: white;
  font-weight: 500;
}

.vision,
.watwijdoen {
  text-align: center;
  padding: 2em;
  padding-bottom: 2em;
}

.vision h2 {
  color: var(--clr-black);
}

.vision p {
  font-weight: 500;
}

.watwijdoen {
  color: white;
  background-color: var(--clr-darkblue);
  position: relative;
  border-bottom-right-radius: var(--border-radius);
}

.watwijdoen h2 {
  color: white;
}

.watwijdoen p {
  color: white;
  font-weight: 500;
}

.watwijdoen::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 30px;
  height: 60px;
  border-top-left-radius: 30px;
  box-shadow: 0 -30px 0 0 var(--clr-darkblue);
  background: transparant;
}
.watwijdoen-lightblue::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-lightblue);
}

.wijzijn {
  display: flex;
  flex-direction: column;
}

.wijzijn img {
  max-width: 50vw;
  margin: 0 auto;
}

.wiewijzijn p {
  font-size: 0.8em;
  line-height: 1.15;
  margin-top: 0.5em;
}

.waardes {
  display: flex;
  flex-direction: column;
}

#watwijdoenpagina {
  background-color: #ecf7fd;
}

.watwijdoenopsomming {
  background-color: var(--clr-lightestblue);
  margin-top: -3em;
  // margin-left: -1em;
  // padding-left: 1em;
  // margin-right: -1em;
  // padding-right: 1em;
  padding-bottom: 2em;
}

.watwijdoenopsomming h3 {
  margin-top: 1em;
}

.wwdfirstitem {
  padding-top: 4em;
}

.wiewijzijn {
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
  flex-direction: column;
}

.wiewijzijn p {
  align-self: center;
}

.wiewijzijntitle {
  color: var(--clr-black);
  margin-top: 2em;
  text-align: center;
  margin-bottom: 1em;
}

.member-title {
  margin-bottom: 0.5em;
}

.member-function {
  margin-top: 0;
  margin-bottom: 3em;
  display: none;
}

.team-fotos {
  display: grid;
  gap: 1.5rem 1.25rem;
  align-items: start;
  grid-template-columns: 1fr 1fr;
}

.team-foto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.photo img {
  display: block;
  object-fit: cover;
  transition: border-radius 0.2s ease-out;
  border-top-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  overflow: hidden;
}

.photo-wrapper {
  position: relative;
  width: 100%; /* The size you want */
}
.photo-wrapper:after {
  content: "";
  display: block;
  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}
.photo-wrapper::before {
  position: absolute;
  content: url("");
  background: center/contain no-repeat url("/assets/images/ul-arrow-white.svg");
  background-size: 25%;
  color: white;
  padding-top: 3em;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.2s ease-out;
  z-index: 10;
  background-color: var(--clr-red);
  border-top-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  opacity: 0;
  display: grid;
  place-items: center;
}

.photo-wrapper:hover::before {
  opacity: 1;
}

.photo-wrapper img {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.photo img:hover {
  // border-top-left-radius: var(--border-radius);
  // border-bottom-right-radius: var(--border-radius);
  // border-top-right-radius: 0px;
  // border-bottom-left-radius: 0;
}

.actievraag-content {
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 3em;
  padding-right: 3em;
  background-color: var(--clr-yellow);
  color: white;
}

.actievraag p {
  color: white;
}

.ontdek {
  font-size: 1.2rem;
  margin-top: 0.5em;
}

.actievraag h2 {
  margin: 0;
  color: white;
}

.partners h2 {
  text-align: center;

  margin-bottom: 1em;
  margin-top: 1.5em;
}

.swiper {
  max-width: 70vw;
  margin-bottom: 2em;
}

.swiper-wrapper {
  align-items: center;
}

.contact {
  background-color: var(--clr-light-grey);
  padding-top: 1rem;
}

.contact h2 {
  margin-top: 2em;
}

.contact-name,
.contact-address {
  padding-bottom: 0;
  margin-bottom: 0;
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 100%;
  // padding-right: 2.5em;
}

form input {
  display: block;
}

input,
textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border-radius: 18px;
  border: 1px solid lightgray;
}

input[type="radio"] {
  margin-bottom: 0;
  border: 1px solid var(--clr-black);
  font-weight: 300;
}

.flex-radio {
  display: flex;
  // align-items: center;
  gap: 1em;
  margin-bottom: 0.5em;
}

input,
textarea {
  margin-bottom: 1em;
}

textarea {
  margin-bottom: 2em;
  resize: none;
  padding-top: 3em;
  min-height: 200px;
  display: inline-block;
}

label {
  color: var(--clr-black);
  margin-bottom: 0.2em;
}

button[type="submit"] {
  margin-left: auto;
  color: white;
  padding: 0.5em 0.8em;
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 0;
}
.did-floating-label {
  color: var(--clr-black);
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.did-floating-input,
.did-floating-select {
  display: block;
  width: calc(100% - 2.75em);
  position: relative;
  height: 36px;
  padding: 26px 20px 0em;
  background: #fff;
  color: var(--clr-black);
  font-family: inherit;
  font-weight: 300;
  font-size: 15px;
  border-radius: 20px;
  border: 1px solid rgba(210, 213, 218, 1);
  // &:focus {
  //   outline: none;
  //   ~ .did-floating-label {
  //     top: -8px;
  //     font-size: 13px;
  //   }
}

textarea.did-floating-input {
  padding-top: 2em;
}

fieldset {
  border: 1px solid lightgray;
  border-radius: 20px;
  width: calc(100% - 2.75em);

  padding: 40px 20px 0em;
  margin-bottom: 1em;
  label {
    font-size: 15px;
    font-weight: 300;
  }
}
legend {
  margin-right: 1em;
}

.hoe-weet-je {
  padding-top: 34px;
}
.informeer {
  // min-height: 100px;
  padding-top: 131px;
}
button[type="submit"].btn-left {
  margin-right: auto;
  margin-left: 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  img {
    width: 50vw;
    margin: 2em auto 3em;
  }
}

main ul {
  line-height: 1.3;
  font-weight: 300;
  list-style: none;
  color: var(--clr-black);
}

main li {
  margin-bottom: 1em;
  position: relative;
}

main li::before {
  position: absolute;
  content: "";
  background: center/contain no-repeat url("/assets/images/ul-arrow.svg");
  color: var(--clr-black);
  left: -14px;
  width: 4.6392px;
  height: 8px;
  top: 6px;
}

.connect-to-perform {
  background: var(--clr-darkblue);
  border-bottom-left-radius: var(--border-radius);
  box-shadow: -70px 0px 0px 0px var(--clr-green),
    -70px 1px 0px 0px var(--clr-green);
  margin-left: 2em;
  padding-top: 2em;
  padding-left: 2em;
  z-index: 2;
  padding-bottom: 1em;
}

.footer-desktop {
  display: none;
}

footer h2 {
  color: white;
  line-height: 1.4;
}

.footer {
  padding-top: 2em;
  padding-left: 2em;
  color: white;

  &-menu {
    display: none;
  }
}

.footer-menu-items img {
  width: 20px;
}

.footer p {
  color: white;
  box-shadow: 0px 50px 0px 0px var(--clr-green),
    -70px 50px 0px 0px var(--clr-green);
}

.footer a:hover, .footer-desktop a:hover {
  color: var(--clr-darkblue);
}

main {
  position: relative;
}
#button-omhoog {
  width: 32px;
  z-index: 1000;
  scroll-behavior: smooth;
}

.button-omhoog-link {
  opacity: 0;
  transform: translateY(100px);
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: 1000;
}

// Werken bij

.werken-bij {
  &__title {
    max-width: 50ch;
    line-height: 1.4;
  }
  h3 {
    margin-top: 1.5em;
  }
  a {
    color: var(--clr-green);
    text-decoration: underline;
    font-weight: 500;
  }
  &__contact {
    p {
      margin-bottom: 0;
    }
  }
  &__uitzendbureaus {
    font-style: italic;
  }
}

//Team-member
.team-member {
  box-shadow: 0px -150px 0px 0px var(--clr-light-grey);
  main & {
    padding-top: 120px;
  }
  .grid-layout {
    display: grid;
    margin-bottom: 2em;
    @media (min-width: 768px) {
      h2 {
        margin-top: 0;
      }
      // margin-top: 170px;
      display: grid;
      gap: 0em 4em;
      grid-template:
        "b a"
        "b c";
      .a {
        grid-area: a;
        max-width: 50ch;
        // min-height: 0;
        // margin: auto;
      }
      .b {
        grid-area: b;
      }
      .c {
        grid-area: c;
        margin-bottom: auto;

        max-width: 50ch;
      }
      grid-template-rows: auto 1fr;
    }
  }
}

.card {
  background: #0bbbb4;
  position: relative;
  margin-bottom: 3em;
  place-self: center;
  p {
    color: white;
  }
  &-title {
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 1em;
  }
  &-phone-number {
    margin-bottom: 0;
  }
  &::after {
    position: absolute;
    content: "";
    top: 100%;
    right: 0;
    width: var(--border-radius);
    height: calc(var(--border-radius) * 2);
    border-top-right-radius: var(--border-radius);
    box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-lightblue);
    background: transparant;
    @media (min-width: 768px) {
      width: var(--border-radius-desktop);
      height: calc(var(--border-radius-desktop) * 2);
      border-top-right-radius: var(--border-radius-desktop);
      box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0
        var(--clr-lightblue);
    }
  }
  // max-width: 300px;
  width: 100%;
  // margin-inline: auto;

  line-height: 1;

  border-radius: 0px var(--border-radius) 0px var(--border-radius);
  @media (min-width: 768px) {
    max-width: 300px;
    margin-bottom: auto;
    border-radius: 0px var(--border-radius-desktop) 0px
      var(--border-radius-desktop);
  }
}

.card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 0px var(--border-radius) 0px 0px;
  @media (min-width: 768px) {
    border-radius: 0px var(--border-radius-desktop) 0px 0px;
  }
}

.card-image {
  overflow: hidden;
}

.card p {
  position: relative;
  z-index: 2;
}

.card .bold {
  font-weight: 600;
}

.card-content {
  padding-top: 1em;
  padding-left: 1.5em;
  padding-bottom: 1em;
  padding-right: 1.5em;
  z-index: 10;
}

.page-contact {
  box-shadow: 0px -200px 0px 0px var(--clr-light-grey);
  h2 {
    margin-top: 4em;
  }
}

.footer a:link,
.footer a:active,
.footer a:visited,
.card a:link,
.card a:active,
.card a:visited {
  color: white;
}

.volgons {
  margin-bottom: 2em;
  img {
    width: 40px;
    margin-left: 1em;
  }
  display: flex;
  align-items: center;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
