.btn {
  border-radius: 100px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  display: inline-block;
  transition: all 0.2s ease-out;

  a {
    text-decoration: none;
    font-weight: 500;
  }
  &-outline {
    border: 1px solid var(--clr-black);
    background-color: transparent;
    a {
      color: var(--clr-black);
    }
    &:hover {
      background-color: var(--clr-grey);
      border: 1px solid var(--clr-grey);
    }
  }

  &-full {
    background-color: var(--clr-darkblue);
    border: 2px solid var(--clr-darkblue);

    a {
      color: white;
    }
    &:hover {
      background-color: var(--clr-lightblue);
      border: 2px solid var(--clr-lightblue);
      
    }
    .connect-to-perform & {
      border: 2px solid var(--clr-lightblue);
      &:hover {
        background-color: var(--clr-grey);
        border: 2px solid var(--clr-grey);
        a {
          color: var(--clr-black);
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.btn-full a:hover {
  color: white;
}

.btn-ctp {
  border: 2px solid var(--clr-lightblue);
  &:hover {
    background-color: var(--clr-grey);
    border: 2px solid var(--clr-grey);
    a {
      color: var(--clr-black);
    }
  }
}
