body {
    font-family: 'Inter', sans-serif;

}

p {
    font-weight: 300;
    line-height: 1.6;
    color: var(--clr-black);
    margin-top: 0;
    a {
        color: var(--clr-darkblue);
        text-decoration: underline;
        transition: color 0.2s ease;
    }
    a:hover {
        color: var(--clr-green);
    }
}

h1 {
    margin-bottom: 0.5em;
}

h2{
    color: var(--clr-black);
    margin-bottom: .5em;
}

h3{
    color: var(--clr-darkblue);
    margin-bottom: .5em;
    margin-top: 0;
}

.watwijdoenopsomming a {
    
            color: var(--clr-darkblue);
            text-decoration: underline;
            transition: color 0.2s ease;
        
}
.watwijdoenopsomming a:hover {

    color: var(--clr-green);
   

}