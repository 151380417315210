a {
  text-decoration: none;
  color: var(--clr-black);
}

nav {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  justify-self: auto;
  padding: 1em;

  position: absolute;
  background: white;
  // position: fixed;
  top: 0;

  left: 0;
  right: 1.5em;
  z-index: 20000;
  border-radius: 0px 0px 30px 0px;
}

.extracorner::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 30px;
  height: 60px;
  border-top-left-radius: 30px;
  box-shadow: 0 -30px 0 0 white;
  background: transparant;
}

.mainlogo {
  width: 150px;
  z-index: 100;
}

.nav-desktop {
  display: none;
  margin-bottom: 0.3em;

  cursor: pointer;
}
.main-menu-list {
  position: relative;
}

.main-menu-list::before {
  content: "";
  position: absolute;
  background: center/contain no-repeat url("/assets/images/arrow-down.svg");
  top: 0.95em;
  right: 1.2em;
  width: 8px;
  height: 4px;
  transform: rotate(0deg);
  color: var(--clr-darkblue);
  transition: transform 0.4s ease-out;
}
.main-menu-list:hover {
  color: var(--clr-darkblue);
}
.main-menu-list:hover::before {
  transform: rotate(180deg);
}

.main-menu-link:link,
.main-menu-link:visited,
.main-menu-link:active {
  color: var(--crl-black);
}

.main-menu {
  color: var(--clr-black);
  z-index: 5;
  cursor: pointer;
}
.nav-desktop ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: none;
  cursor: pointer;
  
}

.nav-desktop li {
  color: var(--clr-black);
  cursor: pointer;
  display: block;
  float: left;
  padding: 0.4rem 2rem;
  position: relative;
  text-decoration: none;
  // transition-duration: 0.5s;
}

ul.dropdown {
  overflow: hidden;

}
.dropdown {
  z-index: 2;
}

.dropdown:last-child {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding-bottom: 1em;
}

.dropdown li:nth-child(2) {
  padding-top: 1.5em;
}

.menu-filler {
  background-color: white;
  opacity: 1;
  min-height: 14.5px;
  width: 100%;
  max-width: 100%;
  &-contact {
    max-width: 7em;
  }
}

.nav-desktop li .dropdownlink {
  color: white;
}

.nav-desktop li:hover,
.nav-desktop li:focus-within {
  cursor: pointer;
}

.nav-desktop li:focus-within a {
  outline: none;
}

.nav-desktop ul li ul {
  a {
    color: white;
  }
  cursor: pointer;
  visibility: hidden;
  background: var(--clr-darkblue);
  color: white;
  opacity: 0;
  min-width: max-content;
  position: absolute;
  transition: opacity 0.2s ease-out;
  margin-top: 0rem;
  left: 0;
  right: 0;
  // display: none;
  // border: var(--clr-black) solid 1px;
}

.nav-desktop ul li:hover > ul,
.nav-desktop ul li:focus-within > ul,
.nav-desktop ul li ul:hover,
.nav-desktop ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

.dropdown-link::before {
  position: absolute;
  content: "";
  background: center/contain no-repeat url("/assets/images/ul-arrow-white.svg");

  left: 20px;
  width: 4.6392px;
  height: 8px;
  bottom: 11px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.nav-desktop ul li ul li:hover .dropdown-link::before {
  opacity: 1;
}

.nav-desktop ul li ul li {
  clear: both;
  width: 100%;
}

.nav-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;

  background: white;
  color: black;
  opacity: 0;
  transition: all 0.4s ease-out;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8em;
  transform: translateY(-100%);
  padding-bottom: 2em;
}

.nav-menu ul {
  margin-top: 3em;
  width: 90%;
  color: black;
  display: block;
  list-style: none;
}

.nav-menu ul li {
  padding-left: 1em;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  border-bottom: 1px solid var(--clr-darkblue);
  font-weight: 500;
}

.nav-menu ul li li {
  border-bottom: 0;
  font-weight: 300;
  padding-bottom: 0;
}

.nav-menu.open {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.2 ease-out;
  transform: translateY(0);
}

.nav-menu.open .submenu-mobile a {
  pointer-events: all;
}

.nav-menu ul {
  padding: 0;
  margin: 0 auto;
}

.nav-menu a {
  color: var(--clr-darkblue);
}

.submenu-mobile {
  height: 0;
  opacity: 0;
  // pointer-events: none;
  transition: all 0.2s ease-out;
}

.submenu-mobile a {
  pointer-events: none;
}

.submenu-mobile-toggle2.open .submenu-mobile {
  height: 9em;
  opacity: 1;
}

.submenu-mobile-toggle1.open .submenu-mobile,
.submenu-mobile-toggle3.open .submenu-mobile {
  height: 3.5em;
  opacity: 1;
}

.li-outer.open::after {
  transform: rotate(180deg);
}

.li-outer {
  position: relative;
}

.li-outer::after {
  content: "";
  position: absolute;
  background: center/contain no-repeat url("/assets/images/arrow-down.svg");
  top: 1.2em;
  right: 1em;
  width: 8px;
  height: 4px;
  transform: rotate(0deg);
  color: var(--clr-darkblue);
  transition: transform 0.2s ease-out;
}

.btn-menu {
  align-self: center;
}
