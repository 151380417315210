.Donkerblauw {
  background-color: var(--clr-darkblue);
}
.Donkerblauw::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-darkblue);
  @media (min-width: 768px) {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0
      var(--clr-darkblue);
  }
}

.Lichtblauw {
  background-color: var(--clr-lightblue);
}
.Lichtblauw::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-lightblue);
  @media (min-width: 768px) {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0
      var(--clr-lightblue);
  }
}
.Geel {
  background-color: var(--clr-yellow);
}
.Geel::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-yellow);
  @media (min-width: 768px) {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0 var(--clr-yellow);
  }
}

.bg-geel {
  background-color: var(--clr-yellow);
}

.Groen {
  background-color: var(--clr-green);
}
.Groen::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-green);
  @media (min-width: 768px) {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0 var(--clr-green);
  }
}

.Grijs {
  background-color: var(--clr-grey);
}

.Rood {
  background-color: var(--clr-red);
}
.Rood::after {
  box-shadow: 0 calc(var(--border-radius) * -1) 0 0 var(--clr-red);
  @media (min-width: 768px) {
    box-shadow: 0 calc(var(--border-radius-desktop) * -1) 0 0 var(--clr-red);
  }
}

.lightestblue {
  color: rgba(236, 247, 253, 1);
}

.bg-lightestblue {
  color: rgba(236, 247, 253, 1);
}

.clr-black {
  color: var(--clr-black);
}

button[type="submit"].clr-black {
  color: var(--clr-black);
}

.bg-grey {
  background-color: var(--clr-light-grey);
}

.bg-rood {
  background-color: var(--clr-red);
}

.bg-lightblue {
  background-color: var(--clr-lightblue);
}
.border-left-green {
  border-left: 2px solid var(--green);
}
.bold {
  font-weight: 500;
}

.tight-text {
  line-height: 1;
}

.relative {
  position: relative;
}
.center {
  text-align: center;
}

.centered {
  display: flex;
  justify-content: center;
}
.margin-top-1 {
  margin-top: 1em;
}
.margin-top-2 {
  margin-top: 2em;
}

.margin-top-6 {
  margin-top: 6em;
}

.margin-bottom-1em {
  margin-bottom: 1em;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-2 {
  margin-bottom: 2em;
}

.margin-bottom-4 {
  margin-bottom: 4em;
}
.block {
  display: block;
}

.max-content {
  width: max-content;
}

.no-border {
  border: 0;
}

.fs-12px {
  font-size: 12px;
}

.fs-15px {
  font-size: 15px;
}

.fs-16px {
  font-size: 16px;
}

.fs-20px {
  font-size: 20px;
}

.current {
  color: var(--clr-darkblue);
  &:link {
    color: var(--clr-darkblue);
  }
  &:visited {
    color: var(--clr-darkblue);
  }
}

.padding-top-large {
  padding-top: 8em;
  @media (min-width: 768px) {
    padding-top: 4em;
  }
}

.blue-shadow {
  box-shadow: 0px -200px 0 0 var(--clr-darkblue);
}

.lightblue-shadow {
  box-shadow: 0px -200px 0 0 var(--clr-lightblue);
}

.wat-wij-doen h2,
.wat-wij-doen p {
  color: rgba(236, 247, 253, 1);
}
.flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}

.underline:hover {
  text-decoration: underline;
}
